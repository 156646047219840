import "../styles.scss";
import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <section className="policy-section">
      <div className="container">
        <h2>
          Information clause on the processing of users' basic personal data
        </h2>
        <span>Data Administrator</span>
        <p>
          HUBPOINT Spółka z ograniczoną odpowiedzialnością Spółka komandytowa
          with its registered office in Kraków at ul. Zawiłej No. 57, 30-390
          Kraków, registered in the Register of Entrepreneurs of the National
          Court Register by the District Court for Kraków-Śródmieście in Kraków,
          11th Commercial Division of the National Court Register under the KRS
          number 0000716584, NIP 6762544428, REGON 369365000.
        </p>
        <span>Purposes of data processing</span>
        <ul>
          <li>conclusion of the contract,</li>
          <li>executing of the contract,</li>
          <li>
            executing an agreement concluded with a credit institution financing
            the investment,
          </li>
          <li>investigation and defense against claims,</li>
          <li>
            contact with the client for the execution of obligations resulting
            from concluded contracts and applicable regulations,
          </li>
          <li>execution of tax and accounting obligations;</li>
        </ul>
        <span>Obtaining information on the processing of personal data</span>
        <ul>
          <li>e-mail: biuro@hubpoint.pl</li>
          <li>phone number: +48 513 030 484</li>
        </ul>
        <span>Legal grounds for processing</span>
        <ul>
          <li>agreement,</li>
          <li>legal obligation,</li>
          <li>arrangement,</li>
          <li>legitimate interest of the Data Administrator;</li>
        </ul>
        <span>Data recipients</span>
        <ul>
          <li>entities processing data on behalf of the Data Administrator,</li>
          <li>entities providing postal and courier services,</li>
          <li>banks and other credit institutions,</li>
          <li>
            entities supporting the Data Administrator in the conducted
            activity;
          </li>
        </ul>
        <span>Data processing rights</span>
        <ul>
          <li>the right to access personal data,</li>
          <li>
            the right to request rectification, disclosure, correction, deletion
            of personal data,
          </li>
          <li>
            the right to request the restriction of the processing of personal
            data,
          </li>
          <li>the right to transfer personal data,</li>
          <li>the right to withdraw consent,</li>
          <li>the right to object.</li>
        </ul>
        <span>I. Personal Data Administrator</span>
        <p>
          The administrator of your personal data is the company under the name
          HUBPOINT Spółka z ograniczoną odpowiedzialnością Spółka komandytowa
          with its registered office in Krakow at ul. Zawiłej No. 57, 30-390
          Kraków, registered in the Register of Entrepreneurs of the National
          Court Register by the District Court for Kraków-Śródmieście in Kraków,
          11th Commercial Division of the National Court Register under the
          number KRS 0000716584, NIP 6762544428, REGON 369365000 (in the text
          also "the Company").
          <br />
          <br />
          You can contact the Company in the following way:
        </p>
        <ul>
          <li>in writing: ul. Zawiła 57c, 30-390 Kraków</li>
          <li>electronically: biuro@hubpoint.pl</li>
          <li>by phone: 513 030 484</li>
        </ul>
        <span>
          II. The purposes of processing personal data and the legal basis for
          their processing
        </span>
        <p>
          The company processes your personal data because it is necessary for:
        </p>
        <ul>
          <li>
            conclusion and execution of a contract, including the conclusion of
            a contract (Article 6 (1) (b) of the GDPR);
          </li>
          <li>
            contacting you in order to inform you about the execution of the
            contract and the obligations under the law (art. 6 ust. 1 lit. b i c
            RODO);
          </li>
          <li>
            contacting you to inform you about the conditions of a contract,
            contacting in response to the contact form filled by you on the
            website (art. 6 ust. 1 lit. a i b RODO);
          </li>
          <li>
            tax and accounting purposes in connection with the execution of the
            contract concluded with you, for the fulfillment of the legal
            obligation incumbent on the Company (art. 6 ust. 1 lit. c RODO);
          </li>
          <li>
            archiving documentation related to the execution of the contract or
            contracts concluded with you, until the claims arising from them are
            time-barred, also to secure information, in the event of a legal
            need to prove facts, which is the legitimate interest of the Company
            (art. 6 ust. 1 lit. f RODO);
          </li>
          <li>
            conclusion and execution of an agreement concluded with a credit
            institution (including a bank) financing the implementation of the
            investment, which is the legitimate interest of the Company (art. 6
            ust. 1 lit. f RODO);
          </li>
          <li>
            possible determination, investigation or defense against claims
            resulting from the contract concluded with you by the Company, which
            is the legitimate interest of the Company (art. 6 ust. 1 lit. f
            RODO).
          </li>
        </ul>
        <p>The company processes your personal data on the basis of:</p>
        <ul>
          <li>consent expressed by you,</li>
          <li>an agreement concluded by you with the Company,</li>
          <li>applicable law, in particular:</li>
          <li className="policy-section__no-list-style">
            the Act of November 17, 1964 - Civil Code,
          </li>
          <li className="policy-section__no-list-style">
            the Accounting Act of September 29, 1994,
          </li>
          <li className="policy-section__no-list-style">
            the Act of July 6, 1982 on land and mortgage registers and mortgage,
          </li>
          <li className="policy-section__no-list-style">
            the Act of August 29, 1997 - Tax Ordinance,
          </li>
          <li className="policy-section__no-list-style">
            the Act of March 11, 2004 on tax on goods and services;
          </li>
          <li className="policy-section__no-list-style">
            the Act of July 26, 1991 on personal income tax;
          </li>
          <li className="policy-section__no-list-style">
            the Act of July 26, 1991 on corporate income tax;
          </li>
          <li className="policy-section__no-list-style">
            the Act of August 26, 1997 - Banking Law;
          </li>
          <li>
            the legitimate interest of the Company, which is, inter alia:
            fulfillment of tax and accounting obligations; arrangement,
            securing, pursuing claims or defense against claims;
          </li>
        </ul>
        <span>III. Recipients of personal data</span>
        <ul>
          <li className="policy-section__no-list-style">
            1. entities processing data on behalf of the Company (including
            Proxies of the Company, members of its management or supervisory
            bodies, employees or associates);
          </li>
          <li className="policy-section__no-list-style">
            2. entities providing postal or courier services in order to deliver
            correspondence to you;
          </li>
          <li className="policy-section__no-list-style">
            3. banks or other credit institutions financing the investment
          </li>
          <li className="policy-section__no-list-style">
            4. subcontractors, i.e. entities whose services the Company uses to
            process them:
          </li>
          <li>accounting firm,</li>
          <li>law firm,</li>
          <li>a notary's office,</li>
          <li>IT company,</li>
          <li>investment advisor,</li>
          <li>credit institution (bank),</li>
          <li>
            a company intermediating in servicing the performance of a loan
            agreement concluded to finance the implementation of the investment,
          </li>
          <li>
            companies participating in the construction process in connection
            with the implementation of the investment (e.g. the general
            contractor of the investment or its subcontractors, service
            technicians or guarantors of works or materials and equipment used
            for the investment) in order to guarantee the proper performance of
            the contract concluded with you, including the implementation of
            possible claims for the proper performance of the contract, the
            quality of the work performed and the materials used
          </li>
          <li>general partner of the Company</li>
          <li className="policy-section__no-list-style">
            Such entities process personal data on the basis of an agreement
            concluded with the Company and only in accordance with the Company's
            instructions. Such entities may disclose personal data to further
            subcontractors only on the basis of an agreement with the Company,
            with the Company's knowledge and information, and only in accordance
            with the Company's instructions. However, further subcontractors may
            only be the following entities that the Company's subcontractors use
            to process personal data: accounting firm, law firm, notary's
            office, IT company, investment advisor, credit institution (bank),
            intermediary in servicing the performance of a loan agreement
            concluded for the purpose of financing the investment.
          </li>
        </ul>
        <span>IV. Period of personal data storage</span>
        <ul>
          <li className="policy-section__no-list-style">
            According to art. 13 ust. 2 lit a) RODO, We store your personal
            data:
          </li>
          <li>
            personal data obtained in order to conclude a contract, which has
            not been finally concluded, is kept by the Company for the period of
            contract negotiation and until the end of the calendar year
            following the calendar year in which you last contacted the Company;
          </li>
          <li>
            Personal data obtained in connection with the conclusion and
            performance of the contract is stored by the Company for the
            duration of the contract and after its completion, up to the end of
            the period:
          </li>
          <li className="policy-section__dash">
            limitation of potential claims under the contract, and
          </li>
          <li className="policy-section__dash">
            limitation of the performance of accounting or tax obligations, the
            data is stored for the maximum period indicated for purposes:
          </li>
          <li className="policy-section__dash">
            pursuing claims arising from the performance of the contract,
          </li>
          <li className="policy-section__dash">
            performance of obligations resulting from legal regulations, in
            particular accounting and tax obligations.
          </li>
        </ul>
        <span>V. Your rights related to the processing of personal data</span>
        <ul>
          <li className="policy-section__no-list-style">
            You are entitled to:
          </li>
          <li>
            the right to access your personal data and obtain a copy of the
            personal data stored by the Company;
          </li>
          <li>
            the right to request the rectification (correction) of your personal
            data;
          </li>
          <li>
            the right to request the deletion of your personal data, if it is
            believed There are no grounds for the Company to process your
            personal data;
          </li>
          <li>
            the right to request the restriction of the processing of your
            personal data by the Company; you may request that the Company limit
            the processing of your personal data to their storage or performance
            of activities agreed with you:
          </li>
          <li className="policy-section__no-list-style">
            if, in your opinion, the Company has incorrect personal data or
            processes it unjustifiably; or
          </li>
          <li className="policy-section__no-list-style">
            if you do not want the data to be deleted, because you may need them
            to establish, investigate or defend against claims; or
          </li>
          <li className="policy-section__no-list-style">
            for the duration of your objection to the processing of personal
            data;
          </li>
          <li>
            the right to object to the processing of your personal data, due to
            your special situation - in cases where your personal data is
            processed on the basis of the legitimate interest of the Company;
          </li>
          <li>
            the right to transfer your personal data, i.e. the right to receive
            personal data from the Company, in a structured, commonly used,
            machine-readable IT format; you may send these personal data to
            another personal data administrator or request the Company to send
            these personal data to another personal data administrator, if such
            a transfer is technically possible;
          </li>
          <li>
            the right to withdraw consent to the processing of personal data at
            any time, which, however, does not affect compliance with the right
            to process based on consent before its withdrawal;
          </li>
          <li>
            the right to lodge a complaint with the supervisory body, i.e. the
            President of the Personal Data Protection Office, if you believe
            that the Company is processing your personal data unlawfully.
          </li>
        </ul>
        <span>
          VI. Information on the requirement / voluntary provision of data
        </span>
        <p>
          Providing your data is necessary for the execution of the contract as
          well as its performance and settlement. If you do not provide the data
          to the Company, the Company will be forced to refuse to conclude the
          contract.
        </p>
        <span>
          VII. Processing of personal data in an automated manner Your personal
          data will not be processed in an automated manner (including in the
          form of profiling).
        </span>
      </div>
    </section>
  );
};

export default PrivacyPolicyContent;
